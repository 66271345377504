import { useState, useEffect, ReactNode } from 'react';
import LanguageContext from './LanguageContext';
import en from '../lang/en';
import es from '../lang/es';
import { getLocalStorageValue, setLocalStorageValue } from '@xompass/web-sdk';

const LANGS = {
  en: 'en',
  es: 'es',
} as const;

type ValidLanguage = keyof typeof LANGS;

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  let urlLang = new URLSearchParams(window.location.search).get('lang') || 'en';
  if (urlLang !== 'en' && urlLang !== 'es') {
    urlLang = 'en';
  }

  const [language, setLanguage] = useState<ValidLanguage>(
    urlLang as ValidLanguage
  );
  const [currentLanguage] = useState({
    es: es,
    en: en,
  });

  useEffect(() => {
    const savedLanguageLocal = getLocalStorageValue('globalLanguage');
    if (
      !savedLanguageLocal ||
      Object.keys(LANGS).includes(savedLanguageLocal)
    ) {
      return;
    }

    setLanguage(savedLanguageLocal);
  }, []);

  useEffect(() => {
    setLocalStorageValue('globalLanguage', language);
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        currentLangData: currentLanguage[language],
      }}
    >
      <div className={`language--${language}`}>{children}</div>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
