import { ConfigProvider, Result, theme as antTheme } from 'antd';
import { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import '../styles/not-authenticated.scss';
import LanguageContext from '../contexts/LanguageContext';

const NotAuthenticated = () => {
  const { theme } = useContext(ThemeContext);
  const {
    general: {
      notAuthenticated,
      Messages: { notAuthorised },
    },
  } = useContext(LanguageContext).currentLangData;
  return (
    <div className="not-authenticated">
      <ConfigProvider
        theme={{
          algorithm:
            theme === 'dark'
              ? antTheme.darkAlgorithm
              : antTheme.defaultAlgorithm,
        }}
      >
        <Result
          status="403"
          title={notAuthenticated}
          subTitle={notAuthorised}
        />
      </ConfigProvider>
    </div>
  );
};

export default NotAuthenticated;
