import {
  Customer,
  Customer_findById,
  Manager,
  Project,
} from '@xompass/web-sdk';
import { LoaderFunctionArgs } from 'react-router-dom';
import queryClient from '../GlobalQueryClient';

export type CurrentCustomerLoader = {
  customer: Customer;
  managers: Manager[];
  projects: Project[];
};

export async function currentCustomerLoader(
  args: LoaderFunctionArgs
): Promise<CurrentCustomerLoader> {
  const customerId = args.params.customerId;
  if (!customerId) {
    throw new Response('No customer id provided', { status: 400 });
  }

  const customer = await queryClient.fetchQuery(
    ['router', 'Customer_findById', customerId],
    () =>
      Customer_findById(customerId, {
        include: [
          { relation: 'container' },
          {
            relation: 'projects',
            scope: { fields: ['id', 'name'] },
          },
          {
            relation: 'managers',
            scope: { fields: ['id', 'name', 'surname', 'email'] },
          },
        ],
      }),
    { staleTime: 1000 * 60 * 60 }
  );

  return {
    customer,
    managers: customer.managers || [],
    projects: customer.projects || [],
  };
}
