export const Colors = {
  white: 'White',
  black: 'Black',
  silver: 'Silver',
  gray: 'Gray',
  red: 'Red',
  blue: 'Blue',
  green: 'Green',
  yellow: 'Yellow',
  brown: 'Brown',
  gold: 'Gold',
  orange: 'Orange',
  pink: 'Pink',
  purple: 'Purple',
};
