export const ForensicReconstruction = {
  forensicReconstruction: 'Reconstrucción forense',
  cameraOverview: 'Todas las cámaras',
  loadTemplate: 'Cargar plantilla',
  saveTemplate: 'Guardar plantilla',
  templateTitle: 'Título de la plantilla',
  templateDescription: 'Descripción de la plantilla',
  selectCameras: 'Seleccionar cámaras',
  selectCamera: 'Seleccionar cámara',
  addANewCamera: 'Agregar cámara',
  addCameras: 'Agregar cámaras',
  editCamera: 'Editar cámara',
  cameraName: 'Nombre de la cámara',
  addElementsToStoryline: 'Agregar elementos a storyline',
  createNewStoryline: 'Crear nuevo storyline',
  createStoryline: 'Crear storyline',
  editStoryline: 'Editar storyline',
  deleteStoryline: 'Eliminar storyline',
  exportStoryline: 'Exportar storyline',
  addToStoryline: 'Agregar a storyline',
  goToStorylines: 'Ir a Storylines',
  addANewCameraToBegin: 'Agregar una cámara para comenzar',
  viewSelectedImages: 'Ver imágenes seleccionadas',
  selectSpeedMultiplier: 'Seleccione velocidad',
  downloadSelectedImages: 'Descargar imágenes seleccionadas',
  hideDetections: 'Ocultar detecciones',
  showDetections: 'Mostrar detecciones',
  Tutorial: {
    step1Title:
      '¡Te damos la bienvenida a nuestra nueva herramienta de Reconstrucción Forense!',
    step1Description: `En esta herramienta podrás explorar momentos precisos capturados por las cámaras para reconstruir eventos pasados y crear una línea de tiempo detallada.`,
    step2Title: 'Agregar nueva cámara',
    step2Description:
      'Aquí podrás visualizar las cámaras seleccionadas y también tienes la opción de añadir nuevas cámaras a tu lista.',
    step3Title: 'Selector de hora',
    step3Description:
      'En esta sección, puedes seleccionar la hora específica en la que deseas realizar tu consulta. Utiliza las flechas simples para desplazarte entre las horas y las flechas dobles para navegar entre los días...',
    step4Title: 'Selector de hora',
    step4Description:
      '...¡O también puedes hacer scrolling para moverte en el tiempo! De esta manera, podrás ajustar fácilmente la hora deseada para obtener los resultados precisos que buscas.',
    step5Title: 'Otras acciones',
    step5Description:
      'Puedes visualizar y descargar las imágenes que hayas seleccionado previamente. Además, puedes guardar y cargar plantillas que contengan las cámaras seleccionadas, lo que te permitirá tener configuraciones guardadas para futuras consultas.',
    step6Title: '¡Esperamos que disfrutes esta nueva herramienta!',
    step6Description:
      'Esperamos que la disfrutes al máximo y te sientas libre de explorarla a tu propio ritmo. Si tienes alguna pregunta o necesitas ayuda, no dudes en consultarnos. ¡Que tengas una excelente experiencia utilizando esta nueva herramienta!',
  },
  Messages: {
    addSnapshotTitle: '¿Agregar captura?',
    addSnapshotDescription:
      'La captura del video será añadida a la sección Imágenes.',
    enterTemplateTitle: 'Ingrese el título de la plantilla',
    enterTemplateDescription: 'Ingrese la descripción de la plantilla',
    templateTitleRequired: '¡Debes ingresar un título para la plantilla!',
    deleteCameraConfirmationTitle: '¿Eliminar cámara',
    deleteCameraConfirmationDescription:
      'La selección de imágenes asociada se perderá.',
    noImages: 'Esta cámara aún no tiene imágenes.',
    noVideos: 'Esta cámara aún no tiene vídeos.',
    cameraNameRequired: '¡Debe ingresar un nombre para la cámara!',
    selectCameraMessage:
      'Selecciona una cámara para ver sus imágenes y videos.',
    noImagesSelected: 'No hay imágenes seleccionadas.',
    yourStorylineHasBeenUpdated:
      'El storyline ha sido actualizado exitosamente.',
  },
  UploadTemplateModal: {
    uploadTemplate: 'Cargar plantilla',
    Messages: {
      clickOrDragFile:
        'Haz click o arrastra y suelta el archivo aquí para subirlo',
      youDoNotHavePermission:
        'No tienes permiso para ver el contenido de esta plantilla.',
      pleaseUploadADifferentJSON:
        'Por favor, carga otro archivo JSON (.json) o contacta a tu administrador.',
      pleaseUpload: 'Por favor carga',
      aSingleJSONFile: 'un único archivo JSON (.json)',
    },
  },
  WheelPicker: {
    resetDate: 'Reiniciar fecha',
    previousDay: 'Día anterior',
    previousHour: 'Hora anterior',
    nextDay: 'Día siguiente',
    nextHour: 'Hora siguiente',
  },
  SelectedImagesModal: {
    doYouWantToDeselect: '¿Deseas deseleccionar esta imagen?',
    theCurrentImageWillBeRemoved:
      'La imagen actual se eliminará de las imágenes seleccionadas. Para volver a agregarla se debe hacer manualmente.',
  },
};
