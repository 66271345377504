import { UserCrendentials } from '@xompass/web-sdk';
import { useContext, useState } from 'react';
import { ApiClientContext } from '../contexts/ApiClientContext';
import { AuthContextType } from '../contexts/AuthContextProvider';
import { useQueryClient } from '@tanstack/react-query';

/**
 * This hook is used to login and logout a user.
 */
export const useAuth = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();
  const [authCtx, setAuthCtx] = useState<AuthContextType>({
    principal: apiClient?.getCachedUser() || null,
    token: apiClient?.getAccessToken() || null,
  });

  const login = async (credentials?: UserCrendentials) => {
    if (!apiClient) {
      throw new Error('ApiClient is not initialized');
    }

    const principalType = apiClient.getPrincipalType();

    if (!principalType) {
      throw new Error('Principal type is not set');
    }

    let user = null;
    if (credentials) {
      user = await queryClient.fetchQuery(['auth', 'login'], () =>
        apiClient.login(credentials, principalType)
      );
    } else if (apiClient.getAccessToken() && apiClient.getUserId()) {
      user = await queryClient.fetchQuery(['auth', 'login'], () =>
        apiClient.login()
      );
    } else {
      throw new Error('Credentials are not provided');
    }

    setAuthCtx({
      principal: user,
      token: apiClient.getAccessToken() || null,
    });
    return user;
  };

  const logout = async () => {
    if (!apiClient) {
      throw new Error('ApiClient is not initialized');
    }

    await apiClient.logout();
    setAuthCtx({
      principal: null,
      token: null,
    });
  };

  return {
    authCtx,
    setAuthContext: setAuthCtx,
    login,
    logout,
  };
};
