export const GranularityDashboard = {
  granularity: 'Granularity',
  volume: 'Volume',
  percentage: 'Percentage',
  size: 'Size',
  portico: 'Gantry',
  empty: 'Empty',
  material: 'Material',
  desmonte: 'Desmonte',
};
