export const Colors = {
  white: 'Blanco',
  black: 'Negro',
  silver: 'Plateado',
  gray: 'Gris',
  red: 'Rojo',
  blue: 'Azul',
  green: 'Verde',
  yellow: 'Amarillo',
  brown: 'Café',
  gold: 'Dorado',
  orange: 'Naranjo',
  pink: 'Rosado',
  purple: 'Morado',
};
