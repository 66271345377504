import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth } from '../hooks/useAuth';
import { ApiClientContext } from './ApiClientContext';
import {
  getHTTPErrorMessage,
  setLocalStorageValue,
  User,
  UserType,
} from '@xompass/web-sdk';
import NotAuthenticated from '../pages/NotAuthenticated';

export interface AuthContextType {
  principal: User | null;
  token: string | null;
}

export type AuthContextPair = [
  AuthContextType | null,
  React.Dispatch<React.SetStateAction<AuthContextType>>
];

export const AuthContext = createContext<AuthContextPair>([
  {
    principal: null,
    token: null,
  },
  () => null,
]);

type LoginStatus = {
  user: User | null;
  isError: boolean;
  error?: Error | null;
};

/**
 * Context provider for the authentication context
 */
export function AuthContextProvider(props: { children: ReactNode }) {
  const apiClient = useContext(ApiClientContext);
  const { authCtx, setAuthContext, login } = useAuth();

  const { token, id, userType } = getUserParameters();
  apiClient.setAccessToken(token);
  apiClient.setUserId(id);
  apiClient.setPrincipalType(userType);

  const [loginResult, setLoginResult] = useState<LoginStatus>({
    user: null,
    isError: false,
  });

  useEffect(() => {
    if (token && id && userType) {
      login()
        .then((user) => {
          setLoginResult({
            user,
            isError: false,
          });
        })
        .catch((err) => {
          setLoginResult({
            user: null,
            isError: true,
            error: err,
          });
        });
    }
  }, []);

  // If there is no token, id or user type, then the user is not authenticated
  if (!token || !id || !userType) {
    return (
      <AuthContext.Provider value={[authCtx, setAuthContext]}>
        <NotAuthenticated />
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={[authCtx, setAuthContext]}>
      {loginResult.isError
        ? getHTTPErrorMessage(loginResult.error)
        : loginResult.user
        ? props.children
        : ''}
    </AuthContext.Provider>
  );
}

/**
 * Gets the user parameters from the URL
 */
function getUserParameters(): {
  token: string;
  id: string;
  userType: UserType;
} {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('tk') || '';
  const id = params.get('uid') || '';
  const userType = params.get('ut') || '';

  if (!userType || !['Admin', 'Manager'].includes(userType)) {
    return { token, id, userType: 'Manager' };
  }

  if (userType === 'Admin') {
    const varId = params.get('varId') || '';
    setLocalStorageValue('vsaas$varId', varId);
  } else {
    setLocalStorageValue('vsaas$varId', null);
  }

  return { token, id, userType: userType as UserType };
}
