import { Colors } from './es/Colors';
import { CountReport } from './es/CountReport';
import { countries } from './es/Countries';
import { detections } from './es/Detections';
import { DetectionsHistory } from './es/DetectionsHistory';
import { Errors } from './es/Errors';
import { ForensicReconstruction } from './es/ForensicReconstruction';
import { GranularityDashboard } from './es/GranularityDashboard';
import { LicensePlatesSearch } from './es/LicensePlatesSearch';
import { OperabilitySummary } from './es/OperabilitySummary';
import { Status } from './es/Status';
import { Storylines } from './es/Storylines';

const es = {
  general: {
    from: 'Desde',
    to: 'Hasta',
    accuracy: 'Precisión',
    add: 'Agregar',
    additionalInformation: 'Información adicional',
    address: 'Dirección',
    allFP: 'Todas',
    and: 'y',
    applyFilters: 'Aplicar filtros',
    attachFiles: 'Adjuntar archivos',
    attachments: 'Adjuntos',
    author: 'Autor',
    camera: 'Cámara',
    cameraP: 'Cámaras',
    cancel: 'Cancelar',
    category: 'Categoría',
    centre: 'Centro',
    characters: 'Caracteres',
    checkAll: 'Seleccionar todo',
    clearFilters: 'Limpiar filtros',
    clearSearch: 'Limpiar búsqueda',
    close: 'Cerrar',
    copyToClipboard: 'Copiar al portapapeles',
    creationDate: 'Fecha de creación',
    customF: 'Personalizada',
    date: 'Fecha',
    debuggingMode: 'Modo depuración',
    delete: 'Eliminar',
    description: 'Descripción',
    deselect: 'Deseleccionar',
    detections: 'Detecciones',
    download: 'Descargar',
    downloadFile: 'Descargar archivo',
    downloadImage: 'Descargar imagen',
    downloadImages: 'Descargar imágenes',
    downloadRawData: 'Descargar datos crudos',
    edit: 'Editar',
    editMode: 'Modo edición',
    endDate: 'Fecha término',
    elements: 'Elementos',
    example: 'Ejemplo',
    exportAsPDF: 'Exportar como PDF',
    filter: 'Filtrar',
    goBack: 'Volver',
    height: 'Altura',
    hide: 'Ocultar',
    hideLabels: 'Esconder etiquetas',
    images: 'Imágenes',
    languages: {
      english: 'Inglés',
      spanish: 'Español',
    },
    length: 'Largo',
    load: 'Cargar',
    loading: 'Cargando',
    location: 'Lugar',
    login: 'Iniciar sesión',
    maximum: 'Máximo',
    minimum: 'Mínimo',
    month: 'Mes',
    name: 'Nombre',
    next: 'Siguiente',
    notAuthenticated: 'Se requiere autenticación',
    of: 'de',
    preview: 'Vista previa',
    previous: 'Anterior',
    probability: 'Probabilidad',
    recommended: 'Recomendados',
    required: 'Requerido',
    reset: 'Reiniciar',
    results: 'Resultados',
    reload: 'Recargar',
    save: 'Guardar',
    saveChanges: 'Guardar cambios',
    search: 'Buscar',
    searchNoun: 'Búsqueda',
    seeAttachments: 'Ver adjuntos',
    seeSelectedImages: 'Ver imágenes seleccionadas',
    seeDetails: 'Ver detalles',
    selectedImages: 'Imágenes seleccionadas',
    selectCategory: 'Seleccionar categoría',
    selected_LC_F: 'seleccionadas',
    selected_LC_M: 'seleccionados',
    selectLocation: 'Seleccionar lugar',
    show: 'Mostrar',
    showLabels: 'Mostrar etiquetas',
    sort: 'Ordenar',
    startDate: 'Fecha inicio',
    status: 'Estado',
    summary: 'Resumen',
    surname: 'Apellido',
    switchToDarkTheme: 'Cambiar a tema oscuro',
    switchToLightTheme: 'Cambiar a tema claro',
    time: 'Hora',
    timeInterval: 'Intervalo de tiempo',
    type: 'Tipo',
    user: 'Usuario',
    username: 'Nombre de usuario',
    userP: 'Usuarios',
    videos: 'Vídeos',
    yes: 'Sí',
    Messages: {
      browseOrDropFilesToUpload:
        'Haz click o arrastra aquí los archivos para subirlos.',
      fileSizeMayNotExceed:
        'El tamaño total de los archivos no puede exceder los',
      fileSizeExceeded: 'Límite de tamaño excedido.',
      addABriefDescription: 'Escribe aquí una breve descripción',
      noResultsFound: 'No se han encontrado resultados',
      noSelectedElements: 'No hay elementos seleccionados.',
      weAreAboutToRedirectYouTo: 'Serás redirigido a',
      doYouWishToContinue: 'Deseas continuar?',
      error404: 'Lo sentimos, la página a la que quieres acceder no existe.',
      notAuthorised:
        'Lo sentimos, no estás autorizado para acceder a esta página.',
      thisViewIsNotYetAvailableOnMobile:
        'Esta vista aún no se encuentra disponible en su versión móvil',
      viewItOnDesktopInstead:
        'Para visualizarla correctamente, accede a la versión de escritorio',
      noBlankSpacesOrSpecialCharactersAllowed:
        'No se aceptan espacios en blanco ni caracteres especiales.',
    },
    Errors: Errors,
  },
  ForensicReconstruction: ForensicReconstruction,
  Storylines: Storylines,
  LicensePlatesSearch: LicensePlatesSearch,
  GranularityDashboard: GranularityDashboard,
  CountReport: CountReport,
  DetectionsHistory: DetectionsHistory,
  OperabilitySummary: OperabilitySummary,
  detections: detections,
  status: Status,
  colors: Colors,
  countries: countries,
};

export default es;
