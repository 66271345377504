import React from 'react';
import es from '../lang/es';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialLangState: any = {
  language: 'es',
  setLanguage: () => null,
  currentLangData: es,
};

const LanguageContext = React.createContext(initialLangState);
export default LanguageContext;
