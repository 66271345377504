export const Storylines = {
  createStorypoint: 'Create storypoint',
  editStorypoint: 'Edit storypoint',
  deleteStorypoint: 'Delete storypoint',
  selectRelevantItems: 'Select relevant items',
  createNewCategory: 'Create new category',
  editCategory: 'Edit category',
  advancedEditor: 'Advanced editor',
  backgroundColor: 'Background color',
  textColor: 'Text color',
  borderColor: 'Border color',
  manageCategories: 'Manage categories',
  seeFullView: 'See full view',
  sortStorypoints: 'Sort storypoints',
  saveSortingOrder: 'Save sorting order',
  downloadStorylineImages: 'Download storyline images',
  hideRelevantItems: 'Hide relevant items',
  showRelevantItems: 'Show relevant items',
  Status: {
    inProgress: 'In progress',
    closed: 'Closed',
    finished: 'Solved',
  },
  segmentedButtons: {
    allElements: 'All elements',
    notAssociatedToAStorypoint: 'Not associated to a storypoint',
    associatedToThisStorypoint: 'Associated to this storypoint',
  },
  Messages: {
    noCategoriesFound1: `Before creating a storyline you must create at least one category.`,
    noCategoriesFound2: `Press the button to manage your categories.`,
    theFollowingAreItemsRelatedTo:
      'The following are items related to the storyline. Select one or more of these items in order to link them to this storypoint.',
    youHaveNotCreatedAnyStorylinesYet: "You haven't created any storylines yet",
    ifYouWantToCreateOne:
      'If you want to create one, please press the button with the icon',
    doYouWanToDeleteStorypoint: 'Do you really want to delete storypoint',
    doYouWanToDeleteStoryline: 'Do you really want to delete storyline',
    onceYouHaveDeletedAStorypoint:
      "Once you have deleted a storypoint, you won't be able to restore it, and its data will be lost.",
    onceYouHaveDeletedAStoryline:
      "Once you have deleted a storyline, all associated storypoints will be lost and you won't be able to restore them.",
    thereAreNoItemsAssociatedYet:
      'There are no items associated to this storyline yet.',
    storypointNameRequired: 'Please provide a storypoint name!',
    storylineNameRequired: 'Please provide a storyline name!',
    enterANameForYourNewStoryline: 'Enter a name for your new storyline',
    enterANameForYourNewStorypoint: 'Enter a name for your new storypoint',
    enterANameForYourNewCategory: 'Enter a name for your new category',
    categoryNameRequired: 'Please provide a category name!',
    doYouWantToRemoveElement:
      'Do you really want to remove this element from this storyline?',
    itWillAlsoBeRemovedFromAll:
      'It will also be removed from all storypoints to which it is linked.',
    youHaveNotAddedAnyStorypointsYet: 'You have not added any storypoints yet.',
    noElementsMeetTheseFilterCriteria:
      'No elements meet these filter criteria.',
  },
};
