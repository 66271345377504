export const OperabilitySummary = {
  customerSummary: 'Customer summary',
  projects: 'Projects',
  selectMonth: 'Select month',
  monthlyOperationalPaymentFactor: 'Monthly operational payment factor',
  summary: 'Summary',
  totalOperationalCameras: 'Total operational cameras',
  uptimePercentage: 'Uptime percentage',
  averageHoursPerCamera: 'Average hours per camera',
  addedCameras: 'Added cameras',
  removedCameras: 'Removed cameras',
  operatingHours: 'Operating hours',
  thereIsNoSummary: 'There is no summary available for this month.',
  deleted: 'Deleted',
};
