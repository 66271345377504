export const Errors = {
  error400: 'Petición incorrecta.',
  error401: 'Se requiere autenticación.',
  error401Description:
    'La sesión ha expirado. Por favor, vuelve a iniciar sesión para continuar.',
  error403: 'Sin autorización.',
  error403Description: 'No estás autorizado para acceder a esta funcionalidad.',
  error404: 'Recurso no encontrado.',
  error405: 'Acción no permitida.',
  error408: 'Se ha acabado el tiempo de respuesta.',
  error409: 'Se ha generado un conflicto.',
  error410: 'El recurso ya no se encuentra disponible.',
  error422: 'No se puede procesar la petición.',
  error429: 'Se han realizado demasiadas peticiones.',
  error500: 'Error interno del servidor.',
  error501: 'Método no reconocido.',
  error502: 'Puerta de enlace no válida.',
  error503: 'Servicio no disponible.',
  error504: 'Se ha acabado el tiempo de respuesta.',
  generic: '¡Ups! algo ha salido mal.',
  fallbackMessage: 'Por favor recarga la página o vuelve atrás.',
};
