export const DetectionsHistory = {
  detectionsHistory: 'Historial de detecciones',
  selectAnAsset: 'Seleccione un asset',
  selectASensor: 'Seleccione un sensor',
  selectClasses: 'Seleccionar clases',
  selectAColor: 'Select a color',
  numberOfResults: 'Número de resultados',
  numberOfDailyResults: 'Número de resultados del día',
  noSensorsAvailable: 'No hay sensores. Por favor, seleccione otro asset.',
  noDetectionsFound: 'No hay detecciones.',
  noClassesAvailable: 'No hay clases disponibles.',
  hideDetections: 'Ocultar elementos fuera de área de detecciones',
  showDetections: 'Mostrar elementos fuera de área de detecciones',
  results: 'Resultados',
  filteredResults: 'Resultados filtrados',
  probabilityFilterDescription:
    'Este selector de rango permite filtrar según la probabilidad estimada de que una detección sea cierta. Esta probabilidad es sólo referencial.',
  colorFilterDescription:
    'Se considera una detección de un color si este color compone al menos el 10% de la imagen.',
  timeSelectorDescription:
    'Este selector permite filtrar por minutos y segundos dentro de la hora seleccionada en "Resultados". Si se quiere cambiar la hora, esta debe cambiarse en el selector de "Resultados".',
  resultsSelectorDescription:
    'Este selector muestra el número de resultados por hora para el día seleccionado, y permite mostrar los resultados según la hora seleccionada. Para filtrar según minutos y segundos, por favor utiliza el selector que se encuentra arriba a la derecha de esta herramienta.',
  resultsSelectorDescriptionMobile:
    'Este selector muestra el número de resultados por hora para el día seleccionado, y permite mostrar los resultados según la hora seleccionada. Para filtrar según minutos y segundos, por favor utiliza el selector que se encuentra abajo de este histograma.',
  pagination: function (from: number, to: number, total: number): string {
    return `${from} a ${to} de ${total} resultados`;
  },
  youCanNavigateBetweenDetection:
    'Es posible navegar entre detecctiones usando las flechas de dirección',
  maxNumberOfImages:
    'No se pueden seleccionar más imágenes. Se ha alcanzado el límite de 100 imágenes.',
};
