export const Storylines = {
  createStorypoint: 'Crear storypoint',
  editStorypoint: 'Editar storypoint',
  deleteStorypoint: 'Eliminar storypoint',
  selectRelevantItems: 'Selecciona elementos relevantes',
  createNewCategory: 'Crear categoría',
  editCategory: 'Editar categoría',
  advancedEditor: 'Edición avanzada',
  backgroundColor: 'Color de fondo',
  textColor: 'Color de texto',
  borderColor: 'Color de borde',
  manageCategories: 'Administrar categorías',
  seeFullView: 'Ver más',
  sortStorypoints: 'Ordenar storypoints',
  saveSortingOrder: 'Guardar orden',
  downloadStorylineImages: 'Descargar imágenes storyline',
  hideRelevantItems: 'Ocultar elementos relevantes',
  showRelevantItems: 'Mostrar elementos relevantes',
  Status: {
    inProgress: 'En progreso',
    closed: 'Cerrado',
    finished: 'Resuelto',
  },
  segmentedButtons: {
    allElements: 'Todos los elementos',
    notAssociatedToAStorypoint: 'No asociado a un storypoint',
    associatedToThisStorypoint: 'Asociado a este storypoint',
  },
  Messages: {
    noCategoriesFound1: `Antes de poder crear un storyline, debes crear al menos una categoría.`,
    noCategoriesFound2: `Presiona el botón para administrar tus categorías.`,
    theFollowingAreItemsRelatedTo:
      'A continuación encontrarás elementos asociados a la storyline. Selecciona uno o más para enlazarlo(s) a este storypoint.',
    youHaveNotCreatedAnyStorylinesYet: 'Aún no has creado ninguna storyline',
    ifYouWantToCreateOne: 'Para crear una, haz click en el botón',
    doYouWanToDeleteStorypoint: '¿Eliminar el storypoint',
    doYouWanToDeleteStoryline: '¿Eliminar el storyline',
    onceYouHaveDeletedAStorypoint:
      'Una vez eliminado el storypoint, no podrá ser recuperado.',
    onceYouHaveDeletedAStoryline:
      'Una vez eliminado el storyline, todos sus storypoint asociados serán también eliminados y no podrán ser recuperados.',
    thereAreNoItemsAssociatedYet:
      'Aún no hay elementos asociados a este storyline.',
    storypointNameRequired: '¡Debes ingresar un nombre para el storypoint!',
    storylineNameRequired: '¡Debes ingresar un nombre para el storyline!',
    enterANameForYourNewStoryline: 'Ingresa un nombre para el storyline',
    enterANameForYourNewStorypoint: 'Ingresa un nombre para el storypoint',
    enterANameForYourNewCategory: 'Ingresa un nombre para la categoría',
    categoryNameRequired: '¡Debes ingresar un nombre para la categoría!',
    doYouWantToRemoveElement: '¿Eliminar este elemento del storyline?',
    itWillAlsoBeRemovedFromAll:
      'Se eliminará este elemento de todos los storypoints a los que esté vinculado.',
    youHaveNotAddedAnyStorypointsYet: 'Aún no se han agregado storypoints.',
    noElementsMeetTheseFilterCriteria:
      'No hay elementos que cumplan con estas características.',
  },
};
