import { Link, LinkProps } from 'react-router-dom';
import { sendMessageToParent } from '../utils/iframeUtils';

type ParentStatePros = {
  parentState: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parentStateParams?: any;
};

type IFrameLinkProps = LinkProps & ParentStatePros;

export function navigateToParentState(params: ParentStatePros) {
  if (window.parent) {
    sendMessageToParent({
      type: 'goTo',
      payload: {
        state: params.parentState,
        params: params.parentStateParams,
      },
    });
    return true;
  }

  return false;
}

export function IFrameLink(params: IFrameLinkProps) {
  const { parentState, parentStateParams, ...linkParams } = params;

  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const isInsideIframe = navigateToParentState({
      parentState,
      parentStateParams,
    });

    if (isInsideIframe) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (
    <Link {...linkParams} onClick={onClick}>
      {params.children}
    </Link>
  );
}
