import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Spinner } from '../components/Spinner';

export function Root() {
  const entrySpinner = document.getElementById('entry-spinner');
  if (entrySpinner) {
    entrySpinner.remove();
  }

  return (
    <Suspense fallback={<Spinner absolute={true} />}>
      <Outlet></Outlet>
    </Suspense>
  );
}
