import {
  Admin,
  Customer,
  Var,
  Var_GetCustomers,
  Var_findById,
  getLocalStorageValue,
} from '@xompass/web-sdk';
// import { LoaderFunctionArgs } from 'react-router-dom';
import queryClient from '../GlobalQueryClient';
import { pause } from '../../utils/general';

export type CurrentVarLoader = {
  _var: Var | null;
  admins: Admin[];
  customers: Customer[];
};

export async function currentVarLoader(): Promise<CurrentVarLoader> {
  await pause(50);
  const varId = getLocalStorageValue('vsaas$varId');
  if (!varId) {
    return {
      _var: null,
      admins: [],
      customers: [],
    };
  }

  const [_var, customers] = await Promise.all([
    queryClient.fetchQuery(
      ['router', 'Var_findById', varId],
      () =>
        Var_findById(varId, {
          include: [
            {
              relation: 'admins',
              scope: { fields: ['id', 'name', 'surname', 'email'] },
            },
          ],
        }),
      { staleTime: 1000 * 60 * 60 }
    ),
    queryClient.fetchQuery(
      ['router', 'Var_GetCustomers', varId],
      () =>
        Var_GetCustomers(varId, {
          fields: ['id', 'name'],
        }),
      { staleTime: 1000 * 60 * 60 }
    ),
  ]);

  return {
    _var,
    admins: _var.admins || [],
    customers: customers || [],
  };
}
