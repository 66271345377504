import { getLocalStorageValue, setLocalStorageValue } from '@xompass/web-sdk';

type IFrameMessage = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  source?: string;
};

let origin = '';

export function registerIframeListeners() {
  const origins = import.meta.env.VITE_PARENT_ORIGINS;
  if (!origins) {
    return;
  }

  const allowedOrigins = origins.split(',');

  window.addEventListener('message', (event) => {
    if (!allowedOrigins.includes(event.origin)) {
      return;
    }

    const { type, payload } = event.data;

    switch (type) {
      case 'init':
        origin = event.origin;
        setLocalStorageValue('sourceId', payload.id);
        break;
      default:
        console.log(type, payload);
    }
  });
}

export function sendMessageToParent(message: IFrameMessage) {
  const parent = window.parent;
  if (!parent || !origin) {
    return;
  }

  message.source = getLocalStorageValue('sourceId');

  parent.postMessage(message, origin);
}
