import { createContext } from 'react';
import { ApiClient } from '@xompass/web-sdk';

const baseUrl = import.meta.env.VITE_API_PATH;

const apiClient = new ApiClient(baseUrl);

export const ApiClientContext = createContext<ApiClient>(apiClient);

export function ApiClientContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
}
