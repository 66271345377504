import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/App.scss';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const spinner = document.createElement('div');
spinner.className = 'app-spinner-backdrop';
spinner.innerHTML = `<div class="app-spinner"><div class="app-spinner__bg"></div><div class="app-spinner__logo"></div></div>`;
spinner.id = 'entry-spinner';
document.body.appendChild(spinner);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
