export const OperabilitySummary = {
  customerSummary: 'Resumen cliente',
  projects: 'Proyectos',
  selectMonth: 'Seleccionar mes',
  monthlyOperationalPaymentFactor: 'Factor de cobro operativo mensual',
  summary: 'Balance cámaras',
  totalOperationalCameras: 'Total de cámaras operativas',
  uptimePercentage: 'Porcentaje de operatividad',
  averageHoursPerCamera: 'Horas promedio por cámara',
  addedCameras: 'Cámaras agregadas',
  removedCameras: 'Cámaras eliminadas',
  operatingHours: 'Horas operativas',
  thereIsNoSummary: 'No hay resumen disponible para este mes.',
  deleted: 'Borrados',
};
