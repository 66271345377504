export const Errors = {
  error400: 'Bad Request.',
  error401: 'Authentication required.',
  error401Description:
    'The session has expired. Please log in again to continue.',
  error403: 'Unauthorized.',
  error403Description: 'You do not have permission to access this feature.',
  error404: 'Not found.',
  error405: 'Method not allowed.',
  error408: 'Request timeout.',
  error409: 'Conflict.',
  error410: 'Gone.',
  error422: 'Unprocessable content.',
  error429: 'Too many requests.',
  error500: 'Internal server error.',
  error501: 'Not implemented.',
  error502: 'Bad gateway.',
  error503: 'Service unavailable.',
  error504: 'Gateway timeout.',
  generic: 'Oops! something has gone wrong.',
  fallbackMessage: 'Please reload the page or go back.',
};
