import { useState, useEffect, ReactNode } from 'react';
import ThemeContext, { initialThemeState } from './ThemeContext';

type props = {
  children: ReactNode;
};

const ThemeProvider = ({ children }: props) => {
  const params = new URLSearchParams(window.location.search);
  const themeParam = params.get('theme');

  const [theme, setTheme] = useState<string>(
    themeParam || initialThemeState.theme
  );
  const handleThemeChange = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  useEffect(() => {
    if (themeParam) {
      return;
    }
    const localStorage = window.localStorage;
    const savedThemeLocal = localStorage.getItem('globalTheme');

    if (savedThemeLocal) {
      setTheme(savedThemeLocal);
    }
  }, []);

  useEffect(() => {
    if (themeParam) {
      return;
    }
    localStorage.setItem('globalTheme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, handleThemeChange }}>
      <div className={`theme--${theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
