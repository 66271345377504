const searchMethodOptions = [
  {
    label: 'Exact',
    description:
      'The license plate must be exactly the same as the entered value.',
  },
  {
    label: 'Prefix',
    description: 'The license plate must start with the entered value.',
  },
  {
    label: 'Contains',
    description: 'The license plate must contain the entered value.',
  },
  {
    label: 'Fuzzy',
    description:
      'The license plate may differ from the entered value by a specified number of characters.',
  },
];

export const LicensePlatesSearch = {
  license: 'License',
  licensePlate: 'License plate',
  selectLicensePlate: 'Select license plate',
  selectedLicensePlate: 'Selected license plate',
  searchMethod: 'Search method',
  searchMethodOptions: searchMethodOptions,
  maxCharactersDistance: 'Max characters distance',
  maxCharactersDistanceDescription:
    'The maximum number of characters that may differ from the entered license plate (1 to 3 characters).',
  allowTranspositions: 'Allow transpositions',
  allowTranspositionsDescription:
    'If enabled, characters next to each other may be transposed to allow for potential inconsistencies in the text detection process. Example: AB12CD -> BA12CD',
  maxResultsReached: 'Maximum number of results reached',
  maxResultsReachedDescription:
    'Your search has reached the maximum number of results. We will only show you the 1,000 most recent.',
  minNumberOfCharacters: '3 characters minimum.',
  minNumberOfCharacters_label: '3 characters min.',
  licensePlateRequired: 'License plate is required.',
  successfullyCopiedToClipboard:
    'License plate successfully copied to clipboard',
  addLicensePlate: 'Add license plate',
  downloadTemplate: 'Download template',
  editLicensePlate: 'Edita license plate',
  Map: {
    showMap: 'Show map',
    hideMap: 'Hide map',
    expandMap: 'Expand map',
    contractMap: 'Contract map',
    hideMarks: 'Hide marks',
    showMarks: 'Show marks',
    startAnimation: 'Start animation',
    stopAnimation: 'Stop animation',
    detections: 'Detections',
    startingPoint: 'Starting point',
    destination: 'Destination',
    points: 'Points',
    allPointsOnPath: 'All points on path',
    subpaths: 'Subpaths',
    allPointsOnPathDescription:
      'A path consists of points on which the vehicle was detected within the entered time interval.',
    subpathsDescription:
      'A subpath is a part of a path. In other words, it corresponds to points on which the vehicle was detected within a certain bounded time interval. If two detections are separated by more than 6 hours, then they are considered to belong to different subpaths.',
  },
  Blacklist: {
    blacklist: 'Blacklist',
    blacklistingDate: 'Blacklisting date',
    vehicleType: 'Vehicle Type',
    brand: 'Brand',
    model: 'Model',
    year: 'Year',
    updateLicensePlates: 'Update license plates',
    updateBlacklist: 'Update blacklist',
    overrideBlacklist: 'Override blacklist',
    selectVehicleType: 'Select a vehicle type',
    selectColor: 'Select a color',
    Messages: {
      doYouReallyWantToDeleteLicensePlate:
        'Do you really want to delete license plate ',
      onceYouHaveDeletedThisLicensePlate:
        "Once you have deleted this license plate, you won't be able to restore it, and its data will be lost.",
      popConfirmTitle: 'Do you really want to override the blacklist?',
      warning:
        'If you override the blacklist, all license plates which are currently in it will be replaced by those in the Excel (.xlsx) file you provide now. Any data that is not in this new file will be lost.',
      switchButtonWarning:
        'Any data that is not in the Excel (.xlsx) file below will be lost.',
      lineErrorPart1: 'Empty license plate at line number ',
      lineErrorPart2: ' in the Excel file.',
      pleaseUpload: 'Please upload ',
      aSingleExcelFile: 'a single excel (.xlsx) file',
      invalidFormat: 'Invalid format',
      pleaseUploadADifferentExcelFile:
        'Please upload a different excel (.xlsx) file or contact your system administrator.',
    },
  },
  CargoContainerLog: {
    container: 'Container',
    invalidCountry: 'Invalid format for selected country.',
    compliantContainerNumber: 'Compliant container number',
    compliantContainerNumberDescription:
      'An ISO 6346 compliant container number includes a 3-letter owner/operator code, an equipment identifier letter, a 6-digit serial number, and a check digit for validation.',
    calculateCheckDigit: 'Calculate check digit',
    ISOCode: 'ISO Code',
    ISOCodeDescription:
      'Container size and type codes, comprising four characters, include the length (first character), width and height (second character), and container type (third and fourth characters).',
    invalidContainerCode: 'Invalid compliant container number',
    capacity: 'Capacity',
    capacityDescription:
      'It indicated the maximum volume of cargo that can be loaded in the container.',
    mgwDescription:
      'The Maximum Gross Weight (MGW) signifies the most weight the loaded container can carry.',
    tare: 'Tare',
    tareDescription:
      'The true weight of an empty container provided by the manufacturer after the manufacturing process.',
  },
};
