import React from 'react';

export const initialThemeState = {
  theme: 'light',
  setTheme: function (newTheme: string) {
    console.log(newTheme);
    return;
  },
  handleThemeChange: function () {
    return;
  },
};

const ThemeContext = React.createContext(initialThemeState);
export default ThemeContext;
