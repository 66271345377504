const searchMethodOptions = [
  {
    label: 'Exacta',
    description: 'La patente debe ser exactamente igual al valor ingresado.',
  },
  {
    label: 'Comienza con',
    description: 'La patente debe comenzar con el valor ingresado.',
  },
  {
    label: 'Contiene',
    description: 'La patente debe contener el valor ingresado.',
  },
  {
    label: 'Difusa',
    description:
      'La patente puede diferir del valor ingresado en un cantidad máxima definida de caracteres.',
  },
];

export const LicensePlatesSearch = {
  license: 'Patente',
  licensePlate: 'Patente',
  selectLicensePlate: 'Selecciona una patente',
  selectedLicensePlate: 'Patente seleccionada',
  searchMethod: 'Método de búsqueda',
  searchMethodOptions: searchMethodOptions,
  maxCharactersDistance: 'Distancia máxima de caracteres',
  maxCharactersDistanceDescription:
    'El número máximo de caracteres que puede diferir de la patente ingresada (1 a 3 caracteres).',
  allowTranspositions: 'Permitir transposiciones',
  allowTranspositionsDescription:
    'Si está activado, dos caracteres contiguos pueden ser transpuestos para mitigar posibles inconsistencias en el proceso de detección de texto. Ejemplo: AB12CD -> BA12CD',
  maxResultsReached: 'Se ha alcanzado el límite de resultados',
  maxResultsReachedDescription:
    'Tu búsqueda ha alcanzado el límite de resultados. Solo te mostraremos los 1.000 más recientes.',
  minNumberOfCharacters: '3 caracteres mínimo.',
  minNumberOfCharacters_label: '3 caracteres mín.',
  licensePlateRequired: 'Se debe ingresar una patente.',
  successfullyCopiedToClipboard:
    'La patente se ha copiado al portapapeles exitosamente',
  addLicensePlate: 'Agregar patente',
  editLicensePlate: 'Editar patente',
  Map: {
    showMap: 'Mostrar mapa',
    hideMap: 'Esconder mapa',
    expandMap: 'Expandir mapa',
    contractMap: 'Contraer mapa',
    hideMarks: 'Esconder marcadores',
    showMarks: 'Mostrar marcadores',
    startAnimation: 'Iniciar animación',
    stopAnimation: 'Detener animación',
    detections: 'Detecciones',
    startingPoint: 'Punto de partida',
    destination: 'Destino',
    points: 'Puntos',
    allPointsOnPath: 'Todos los puntos en el camino',
    subpaths: 'Subcaminos',
    allPointsOnPathDescription:
      'El camino consiste en los puntos en los que se detectó el vehículo en el intervalo de tiempo ingresado.',
    subpathsDescription:
      'Un subcamino es una parte del camino. Es decir, corresponde a los puntos en los que se detectó el vehículo durante un cierto intervalo acotado de tiempo. Si dos detecciones están separadas por más de 6 horas, se considera que pertenecen a subcaminos distintos.',
  },
  Blacklist: {
    blacklist: 'Lista negra',
    blacklistingDate: 'Fecha registro',
    vehicleType: 'Tipo de vehículo',
    brand: 'Marca',
    model: 'Modelo',
    year: 'Año',
    updateLicensePlates: 'Actualizar patentes',
    updateBlacklist: 'Actualizar lista negra',
    overrideBlacklist: 'Sobreescribir lista negra',
    downloadTemplate: 'Descargar plantilla',
    selectVehicleType: 'Seleciona un tipo de vehículo',
    selectColor: 'Selecciona un color',
    Messages: {
      doYouReallyWantToDeleteLicensePlate:
        '¿Estás seguro de que quieres eliminar la patente ',
      onceYouHaveDeletedThisLicensePlate:
        'Una vez eliminada esta patente, sus información asociada será eliminada y no podrá ser recuperada.',
      popConfirmTitle:
        '¿Estás seguro de que deseas sobreescribir la lista negra?',
      warning:
        'Si sobreescribes la lista negra, todas las patentes que están actualmente en ella serán reemplazadas por las que están en el archivo Excel (.xlsx) que estás subiendo ahora. Cualquier información que no esté presente en este nuevo archivo será eliminada.',
      switchButtonWarning:
        'Cualquier información que no esté presente en el archivo Excel (.xlsx) de abajo será eliminada.',
      lineErrorPart1: 'La línea ',
      lineErrorPart2: ' del archivo Excel contiene una patente vacía.',
      pleaseUpload: 'Por favor carga  ',
      aSingleExcelFile: 'un único archivo Excel (.xlsx).',
      invalidFormat: 'Error de formato',
      pleaseUploadADifferentExcelFile:
        'Por favor carga un archivo Excel (.xlsx) distinto o contacta a tu administrador.',
    },
  },
  CargoContainerLog: {
    container: 'Contenedor',
    invalidCountry: 'Formato no válido para país seleccionado.',
    compliantContainerNumber: 'Identificador ISO',
    compliantContainerNumberDescription:
      'Un código identificador conforme a la norma ISO 6346 se compone de un código de 3 letras del propietario/operador, una letra identificadora del equipo, un número de serie de 6 dígitos y un dígito verificador.',
    calculateCheckDigit: 'Calcular dígito verificador',
    ISOCode: 'Código ISO',
    ISOCodeDescription:
      'Los códigos de tamaño y tipo de contenedor, que comprenden cuatro caracteres, incluyen la longitud (primer carácter), el ancho y la altura (segundo carácter), y el tipo de contenedor (tercer y cuarto caracteres).',
    invalidContainerCode: 'Identificador ISO no válido',
    capacity: 'Capacidad',
    capacityDescription:
      'Indica el volumen máximo de carga que puede llevar el contenedor.',
    mgwDescription:
      'El Peso Bruto Máximo o en inglés Maximum Gross Weight (MGW) corresponde al peso máximo que puede llevar el contenedor.',
    tare: 'Tara',
    tareDescription:
      'El peso del contenedor vacío indicado por el productor luego del proceso de producción.',
  },
};
