export const countries = {
  none: 'None',
  argentina: 'Argentina',
  bolivia: 'Bolivia',
  brazil: 'Brazil',
  chile: 'Chile',
  colombia: 'Colombia',
  ecuador: 'Ecuador',
  paraguay: 'Paraguay',
  peru: 'Peru',
  uruguay: 'Uruguay',
  venezuela: 'Venezuela',
};
