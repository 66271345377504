export const detections = {
  classes: {
    aeroplane: 'Avión',
    aeroplanePl: 'Aviones',
    apparel: 'Prenda de vestir',
    apparelPl: 'Prendas de vestir',
    apple: 'Manzana',
    applePl: 'Manzanas',
    backpack: 'Mochila',
    backpackPl: 'Mochilas',
    bag: 'Bolsa',
    bagPl: 'Bolsas',
    banana: 'Plátano',
    bananaPl: 'Plátanos',
    barrier: 'Barrera',
    barrierPl: 'Barreras',
    baseballbat: 'Bate de Baseball',
    baseballbatPl: 'Bates de Baseball',
    baseballglove: 'Guante de Baseball',
    baseballglovePl: 'Guantes de Baseball',
    bear: 'Oso',
    bearPl: 'Osos',
    bed: 'Cama',
    bedPl: 'Camas',
    bench: 'Banco',
    benchPl: 'Bancos',
    bicycle: 'Bicicleta',
    bicyclePl: 'Bicicletas',
    bird: 'Ave',
    birdPl: 'Aves',
    boat: 'Bote',
    boatPl: 'Botes',
    book: 'Libro',
    bookPl: 'Libros',
    bottle: 'Botella',
    bottlePl: 'Botellas',
    bowl: 'Recipiente',
    bowlPl: 'Recipientes',
    box: 'Caja',
    boxPl: 'Cajas',
    broccoli: 'Brócoli',
    broccoliPl: 'Brócolis',
    bulldozer: 'Bulldozer',
    bus: 'Bus',
    busPl: 'Buses',
    cake: 'Pastel',
    cakePl: 'Pasteles',
    car: 'Automóvil',
    carPl: 'Automóviles',
    carrot: 'Zanahoria',
    carrotPl: 'Zanahorias',
    cat: 'Gato',
    catPl: 'Gatos',
    cellphone: 'Celular',
    cellphonePl: 'Celulares',
    chair: 'Silla',
    chairPl: 'Sillas',
    clock: 'Reloj',
    clockPl: 'Relojes',
    cone: 'Cono',
    conePl: 'Conos',
    cow: 'Vaca',
    cowPl: 'Vacas',
    cup: 'Copa',
    cupPl: 'Copas',
    deliverybag: 'Bolso Delivery',
    deliverybagPl: 'Bolsos Delivery',
    diningtable: 'Comedor',
    diningtablePl: 'Comedores',
    document: 'Documento',
    documentPl: 'Documentos',
    dog: 'Perro',
    dogPl: 'Perros',
    donut: 'Dona',
    donutPl: 'Donas',
    elephant: 'Elefante',
    elephantPl: 'Elefantes',
    equipment: 'Equipamiento',
    equipmentPl: 'Equipamientos',
    excavator: 'Excavadora',
    face: 'Rostro',
    facePl: 'Rostros',
    firehydrant: 'Hidrante',
    firehydrantPl: 'Hidrantes',
    'flatbed-trailer': 'Remolque',
    fork: 'Tenedor',
    forklift: 'Montacargas',
    forkPl: 'Tenedores',
    frisbee: 'Frisbee',
    frisbeePl: 'Frisbees',
    giraffe: 'Jirafa',
    giraffePl: 'Jirafas',
    guard: 'Guardia',
    guardPl: 'Guardias',
    hairdrier: 'Secador de Pelo',
    hairdrierPl: 'Secadores de Pelo',
    handbag: 'Bolso de mano',
    handbagPl: 'Bolsos de mano',
    head: 'Cabeza',
    headPl: 'Cabezas',
    horse: 'Caballo',
    horsePl: 'Caballos',
    hotdog: 'Hot Dog',
    hotdogPl: 'Hot Dogs',
    inspector: 'Inspector',
    inspectorPl: 'Inspectores',
    keyboard: 'Teclado',
    keyboardPl: 'Teclados',
    kite: 'Cometa',
    kitePl: 'Cometas',
    knife: 'Cuchillo',
    knifePl: 'Cuchillos',
    label: 'Etiqueta',
    labelPl: 'Etiquetas',
    laptop: 'Laptop',
    laptopPl: 'Laptops',
    license_label: 'Patente',
    license_labelPl: 'Patentes',
    license_plate: 'Patente',
    license_platePl: 'Patentes',
    machinery: 'Maquinaria',
    mask: 'Mascarilla',
    maskPl: 'Mascarillas',
    microwave: 'Microondas',
    microwavePl: 'Microondas',
    motorbike: 'Motocicleta',
    motorbikePl: 'Motocicletas',
    mouse: 'Mouse',
    mousePl: 'Mouses',
    orange: 'Naranja',
    orangePl: 'Naranjas',
    oven: 'Horno',
    ovenPl: 'Hornos',
    parkingmeter: 'Parquímetro',
    parkingmeterPl: 'Parquímetros',
    person: 'Persona',
    personPl: 'Personas',
    pet: 'Mascota',
    pistol: 'Arma de fuego',
    pistolPl: 'Armas de fuego',
    pizza: 'Pizza',
    pizzaPl: 'Pizzas',
    pottedplant: 'Macetero',
    pottedplantPl: 'Maceteros',
    refrigerator: 'Refrigerador',
    refrigeratorPl: 'Refrigeradores',
    remote: 'Control Remoto',
    remotePl: 'Controles Remoto',
    sandwich: 'Sandwich',
    sandwichPl: 'Sandwiches',
    scissors: 'Tijeras',
    scissorsPl: 'Tijeras',
    scooter: 'Scooter',
    scooterPl: 'Scooters',
    sheep: 'Oveja',
    sheepPl: 'Ovejas',
    shoebox: 'Caja de zapatos',
    shoeboxPl: 'Cajas de zapato',
    sink: 'Lavabo',
    sinkPl: 'Lavabos',
    skateboard: 'Skateboard',
    skateboardPl: 'Skateboards',
    skis: 'Skis',
    skisPl: 'Skis',
    sneakers: 'Zapatilla',
    sneakersPl: 'Zapatillas',
    snowboard: 'Snowboard',
    snowboardPl: 'Snowboards',
    socks: 'Calcetines',
    socksPl: 'Calcetines',
    sofa: 'Sofá',
    sofaPl: 'Sofás',
    spoon: 'Cuchara',
    spoonPl: 'Cucharas',
    sportsball: 'Balón',
    sportsballPl: 'Balones',
    stopsign: 'Pare',
    stopsignPl: 'Pare',
    suitcase: 'Maleta',
    suitcasePl: 'Maletas',
    surfboard: 'Surfboard',
    surfboardPl: 'Surfboards',
    teddybear: 'Oso de Peluche',
    teddybearPl: 'Osos de Peluche',
    tennisracket: 'Raqueta',
    tennisracketPl: 'Raquetas',
    tie: 'Corbata',
    tiePl: 'Corbatas',
    toaster: 'Tostador',
    toasterPl: 'Tostadores',
    toilet: 'Baño',
    toiletPl: 'Baños',
    toothbrush: 'Cepillo de Dientes',
    toothbrushPl: 'Cepillos de Dientes',
    trafficlight: 'Semáforo',
    trafficlightPl: 'Semáforos',
    train: 'Tren',
    trainPl: 'Trenes',
    trashbin: 'Basurero',
    trashbinPl: 'Basureros',
    truck: 'Camión',
    truckPl: 'Camiones',
    tvmonitor: 'TV',
    tvmonitorPl: 'TVs',
    umbrella: 'Paraguas',
    umbrellaPl: 'Paraguas',
    vase: 'Florero',
    vasePl: 'Floreros',
    wineglass: 'Vaso de Vino',
    wineglassPl: 'Vasos de Vino',
    zebra: 'Zebra',
    zebraPl: 'Zebras',
    helmet: 'Casco',
    helmetPl: 'Cascos',
    red_light: 'Luz Roja',
    red_lightPl: 'Luces Rojas',
    green_light: 'Luz Verde',
    green_lightPl: 'Luces Verdes',
    yellow_light: 'Luz Amarilla',
    yellow_lightPl: 'Luces Amarillas',
    cart: 'Carro',
    cartPl: 'Carros',
    fire: 'Fuego',
    firePl: 'Fuegos',
    smoke: 'Humo',
    smokePl: 'Humo',
    material: 'Material',
    materialPl: 'Materiales',
    hopper: 'Tolva',
    hopperPl: 'Tolvas',
    truck_small: 'Camion Pequeño',
    truck_smallPl: 'Camiones Pequeños',
    aljibe: 'Aljibe',
    aljibePl: 'Aljibes',
    mercaderia: 'Mercadería',
    mercaderiaPl: 'Mercadería',
    rock: 'Roca',
    rockPl: 'Rocas',
    desmonte: 'Desmonte',
    desmontePl: 'Desmonte',
    pallet: 'Pallet',
    palletPl: 'Pallets',
    'pallet-jack': 'Pallet Jack',
    'pallet-jackPl': 'Pallet Jacks',
    'pallet-stacker': 'Pallet Stacker',
    'pallet-stackerPl': 'Pallet Stackers',
    worker: 'Trabajador',
    workerPl: 'Trabajadores',
    cat1: 'Categoría 1',
    cat1Pl: 'Categoría 1',
    cat2: 'Categoría 2',
    cat2Pl: 'Categoría 2',
    cat3: 'Categoría 3',
    cat3Pl: 'Categoría 3',
    cat4: 'Categoría 4',
    cat4Pl: 'Categoría 4',
    cylinder: 'Cilindro',
    cylinderPl: 'Cilindros',
    extinguisher: 'Extintor',
    extinguisherPl: 'Extintores',
    crane: 'Grúa',
    cranePl: 'Grúas',
    montacilindro: 'Montacilindro',
    montacilindroPl: 'Montacilindros',
    no_helmet: 'Sin Casco',
    no_helmetPl: 'Sin Cascos',
    gate: 'Portón',
    gatePl: 'Portones',
    text: 'Texto',
    textPl: 'Textos',
    container: 'Contenedor',
    containerPl: 'Contenedores',
    covenant: 'Covenant',
    covenantPl: 'Covenants',
    crst: 'CRST',
    crstPl: 'CRST',
    fedex: 'Fedex',
    fedexPl: 'Fedex',
    premier: 'Premier',
    premierPl: 'Premier',
    umxu: 'UMXU',
    umxuPl: 'UMXU',
    usexpress: 'US Express',
    usexpressPl: 'US Express',
    'bolsa-fajos': 'Bolsa de fajos',
    'bolsa-fajosPl': 'Bolsas de fajos',
    'bolsa-monedas': 'Bolsa de monedas',
    'bolsa-monedasPl': 'Bolsas de monedas',
    carro: 'Carro (esp)',
    carroPl: 'Carros (esp)',
    celular: 'Celular (esp)',
    cuadratura: 'Cuadratura (esp)',
    logo_pp: 'Logo PP',
  },
  poses: {
    NONE: 'No reconocida',
    HANDS_UP: 'Manos arriba',
    ON_FLOOR: 'En el piso',
    RUNNING: 'Corriendo',
    SEATED: 'Sentado',
    STANDING: 'De pie',
    FIGHT: 'Peleando',
    THROWING_THINGS: 'Lanzando objeto',
    SQUAT_HEUR1: 'Agachado método 1',
    SQUAT_HEUR2: 'Agachado método 2',
    EMERGENCY: 'Emergencia',
    points: {
      nose: 'nose',
      l_eye: 'l_eye',
      r_eye: 'r_eye',
      l_ear: 'l_ear',
      r_ear: 'r_ear',
      l_shoulder: 'l_shoulder',
      r_shoulder: 'r_shoulder',
      l_elbow: 'l_elbow',
      r_elbow: 'r_elbow',
      l_wrist: 'l_wrist',
      r_wrist: 'r_wrist',
      l_hip: 'l_hip',
      r_hip: 'r_hip',
      l_knee: 'l_knee',
      r_knee: 'r_knee',
      l_ankle: 'l_ankle',
      r_ankle: 'r_ankle',
      neck: 'neck',
    },
  },
  missingObject: {
    helmet: 'Sin casco',
    lincense_plate: 'Sin patente',
  },
};
