const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export function strRemoveAccents(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Function that return true if a search string is contained in a string
 */
export function strContains(str: string, search: string) {
  return strRemoveAccents(str.toLowerCase()).includes(
    strRemoveAccents(search.toLowerCase())
  );
}

/**
 * Function that return the index of a search string in a string
 * or -1 if the search string is not contained in the string
 */
export function strIndexOf(str: string, search: string) {
  return strRemoveAccents(str.toLowerCase()).indexOf(
    strRemoveAccents(search.toLowerCase())
  );
}

export function strCompare(
  str1: string,
  str2: string,
  order: 'asc' | 'desc' = 'asc'
) {
  const factor = order === 'desc' ? -1 : 1;
  return factor * collator.compare(str1, str2);
}

let textTester: HTMLElement;

export function getTextWidth(
  text: string,
  cssClass: string,
  style: Partial<CSSStyleDeclaration>
) {
  if (!text) {
    return 0;
  }

  const bannedStyles = ['position', 'top', 'left', 'visibility'] as const;

  if (!textTester) {
    textTester = document.createElement('div');
    textTester.style.position = 'absolute';
    textTester.style.top = '-10000px';
    textTester.style.left = '-10000px';
    textTester.style.visibility = 'hidden';
    document.body.appendChild(textTester);
  }

  if (cssClass) {
    textTester.classList.add(...cssClass.split(' '));
  }

  for (const bannedStyle of bannedStyles) {
    delete style[bannedStyle];
  }

  if (style && typeof style === 'object') {
    Object.assign(textTester.style, style);
  }

  textTester.textContent = text;
  const width = Math.ceil(textTester.offsetWidth);

  textTester.className = '';
  for (const prop of Object.keys(style)) {
    textTester.style.removeProperty(prop);
  }
  return width;
}

export function titleCaseWord(word: string) {
  if (!word) {
    return word;
  }
  return word[0].toUpperCase() + word.slice(1);
}
