import {
  Customer_GetProjects,
  Customer_GetProjectsAssets,
  Project,
} from '@xompass/web-sdk';
import { LoaderFunctionArgs } from 'react-router-dom';
import queryClient from '../GlobalQueryClient';

export type CurrentProjectLoader = {
  project: Project;
};

export async function currentProjectLoader(
  args: LoaderFunctionArgs
): Promise<CurrentProjectLoader> {
  const { customerId, projectId } = args.params;

  if (!customerId) {
    throw new Response('No customer id provided', { status: 400 });
  }

  if (!projectId) {
    throw new Response('No project id provided', { status: 400 });
  }

  const getProjects = Customer_GetProjects(customerId, {
    where: { id: projectId },
    include: [
      {
        relation: 'allowedSensorTypes',
        scope: {
          fields: [
            'id',
            'name',
            'type',
            'allowVirtualExpressions',
            'allowFiles',
          ],
        },
      },
      {
        relation: 'yoloClasses',
      },
    ],
  });

  const getAssets = Customer_GetProjectsAssets(customerId, projectId, {
    fields: [
      'id',
      'name',
      'type',
      'icon',
      'assetId',
      'tags',
      '_config.feature.geometry.coordinates',
      'currentHealthStatus',
      // '_config.feature.properties.style.icon.options.rotation',
    ],
  });

  const [projects, assets] = await Promise.all([
    queryClient.fetchQuery(
      ['router', 'Customer_GetProjects', customerId, projectId],
      () => getProjects,
      { staleTime: 1000 * 60 * 60 }
    ),
    queryClient.fetchQuery(
      ['router', 'Customer_GetProjectsAssets', customerId, projectId],
      () => getAssets,
      { staleTime: 1000 * 60 * 30 }
    ),
  ]);

  if (!projects[0]) {
    throw new Error('Project not found');
  }

  const project = projects[0];
  project.assets = assets;

  return { project };
}
