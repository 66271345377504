export const CountReport = {
  countReport: 'Count report',
  countReports: 'Count reports',
  createReport: 'Create report',
  editReport: 'Edit report',
  deleteReport: 'Delete report',
  section: 'Section',
  sections: 'Sections',
  createSection: 'Create section',
  editSection: 'Edit section',
  deleteSection: 'Delete section',
  element: 'Element',
  elements: 'Elements',
  createElement: 'Create element',
  editElement: 'Edit element',
  deleteElement: 'Delete element',
  sortElements: 'Sort elements',
  addSeries: 'Add series',
  addSingleSeries: 'Add series',
  listOfSeries: 'List of series',
  deleteSeries: 'Delete series',
  manageUsers: 'Manage users',
  isSelected: 'Is selected',
  showChanges: 'Show changes',
  noChanges: 'There have not been any changes.',
  addedUsers: 'Added users',
  deletedUsers: 'Deleted users',
  recentChanges: 'Recent changes',
  duplicateElement: 'Duplicate element',
  duplicateSection: 'Duplicate section',
  duplicateReport: 'Duplicate report',
  Messages: {
    selectCountReport: 'Please select a count report on the left sidebar.',
    sectionsEmpty: 'You have not created any sections yet.',
    elementsEmpty: 'You have not created any elements yet.',
    doYouReallyWantToDeleteReport: 'Do you really want to delete count report ',
    onceYouHaveDeletedThisReport:
      "Once you have deleted this count report, you won't be able to restore it, and its data will be lost.",
    doYouReallyWantToDeleteSection: 'Do you really want to delete section ',
    onceYouHaveDeletedThisSection:
      "Once you have deleted this section, you won't be able to restore it, and its data will be lost.",
    doYouReallyWantToDeleteElement: 'Do you really want to delete element ',
    onceYouHaveDeletedThisElement:
      "Once you have deleted this element, you won't be able to restore it, and its data will be lost.",
    dragAndDropSectionsToSortThem: 'Drag and drop sections to sort them.',
    noAvailableReportsFound: 'No available reports found.',
    noSectionsFound: 'No sections found.',
    noElementsFound: 'No elements found.',
    twoSeriesCannotHaveTheSameName: 'Two series cannot have the same name.',
  },
  dateRange: 'Date range',
  dateRangeValues: {
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
  },
  dateRangeOptions: [
    {
      label: 'Daily',
      value: 'day',
    },
    {
      label: 'Weekly',
      value: 'week',
    },
    {
      label: 'Monthly',
      value: 'month',
    },
  ],
  Form: {
    columns: 'Columns',
    groupInterval: 'Aggregation interval',
    addDifference: 'Add difference',
    addChart: 'Add chart',
    xLabel: 'X-axis label',
    yLabel: 'Y-axis label',
    xAxisType: 'X-axis type',
    groupMode: 'Group mode',
    categoryType: 'Category type',
    enterCountReportName: 'Enter count report name',
    enterSectionName: 'Enter section name',
    enterElementName: 'Enter element name',
    enterSeriesName: 'Enter series name',
    enterXLabel: 'Enter X axis label',
    enterYLabel: 'Enter Y axis label',
    enterSuffix: 'Enter suffix',
    enterPrefix: 'Enter prefix',
  },
  elementTypeOptions: [
    { label: 'Indicator', value: 'indicator' },
    { label: 'Chart', value: 'chart' },
    { label: 'Table', value: 'table' },
    { label: 'Heatmap', value: 'heatmap' },
  ],
  groupIntervalOptions: [
    { label: 'Hourly', value: 'hour' },
    { label: 'Daily', value: 'day' },
    { label: 'Weekly', value: 'week' },
    { label: 'Monthly', value: 'month' },
  ],
  groupModeOptions: [
    { label: 'Sum', value: 'sum' },
    { label: 'Average', value: 'average' },
    { label: 'Minimum', value: 'min' },
    { label: 'Maximum', value: 'max' },
  ],
  categoryTypeValues: [
    {
      label: 'Category',
      value: 'category',
    },
    {
      label: 'Datetime',
      value: 'datetime',
    },
  ],
  categoryTypeOptions: [
    { label: 'Hourly', value: 'hourOfDay' },
    {
      label: 'Hourly, only business days',
      value: 'hourOfWeekDay',
    },
    {
      label: 'Hourly, only weekends',
      value: 'hourOfWeekendDay',
    },
    {
      label: 'Daily, labels show day of the week',
      value: 'dayOfWeek',
    },
    {
      label: 'Daily, only business days',
      value: 'weekday',
    },
    {
      label: 'Daily, only weekends',
      value: 'weekend',
    },
    {
      label: 'Daily, labels show day of the month',
      value: 'dayOfMonth',
    },
    {
      label: 'Weekly',
      value: 'weekOfMonth',
    },
  ],
  categoryTypeLabels: {
    hourOfDay: 'Hour',
    hourOfWeekDay: 'Hour (Only business days)',
    hourOfWeekendDay: 'Hour (Only weekends)',
    dayOfWeek: 'Day of the week',
    weekday: 'Day (Only business days)',
    weekend: 'Day (Only weekends)',
    dayOfMonth: 'Day of the month',
    weekOfMonth: 'Week',
  },
  SeriesForm: {
    chartType: 'Chart type',
    suffix: 'Suffix',
    prefix: 'Prefix',
    virtualExpression: 'Virtual expression',
    precision: 'Precision',
    chartTypeOptions: [
      { label: 'Bar', value: 'bar' },
      { label: 'Line', value: 'line' },
      { label: 'Area', value: 'area' },
      { label: 'Scatter', value: 'scatter' },
      { label: 'Spline', value: 'spline' },
      { label: 'Spline area', value: 'splineArea' },
    ],
  },
};
