import { Button, ConfigProvider, Result, theme as antTheme } from 'antd';
import { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import { useRevalidator } from 'react-router-dom';
import { navigateToParentState } from '../components/IFrameLink';
import '../styles/error.scss';
import LanguageContext from '../contexts/LanguageContext';

const Error = () => {
  const { theme } = useContext(ThemeContext);
  const { general } = useContext(LanguageContext).currentLangData;

  const revalidator = useRevalidator();

  return (
    <ConfigProvider
      theme={{
        algorithm:
          theme === 'dark' ? antTheme.darkAlgorithm : antTheme.defaultAlgorithm,
      }}
    >
      <div className="error">
        <Result
          status="500"
          title={general.Errors.generic}
          subTitle={general.Errors.fallbackMessage}
          extra={
            <div className="error__buttons-container">
              <Button
                type="primary"
                style={{ marginBottom: 10 }}
                onClick={() => revalidator.revalidate()}
              >
                {general.reload}
              </Button>
              <Button
                type="text"
                onClick={() => navigateToParentState({ parentState: 'home' })}
              >
                {general.goBack}
              </Button>
            </div>
          }
        />
      </div>
    </ConfigProvider>
  );
};

export default Error;
