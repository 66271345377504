import { ConfigProvider, Result, theme as antTheme } from 'antd';
import { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import '../styles/page-not-found.scss';
import LanguageContext from '../contexts/LanguageContext';

const PageNotFound = () => {
  const { theme } = useContext(ThemeContext);
  const {
    general: {
      Messages: { error404 },
    },
  } = useContext(LanguageContext).currentLangData;
  return (
    <div className="page-not-found">
      <ConfigProvider
        theme={{
          algorithm:
            theme === 'dark'
              ? antTheme.darkAlgorithm
              : antTheme.defaultAlgorithm,
        }}
      >
        <Result status="404" title="404" subTitle={error404} />
      </ConfigProvider>
    </div>
  );
};

export default PageNotFound;
