export const DetectionsHistory = {
  detectionsHistory: 'Detections history',
  selectAnAsset: 'Select an asset',
  selectASensor: 'Select a sensor',
  selectClasses: 'Select classes',
  selectAColor: 'Select a color',
  numberOfResults: 'Number of results',
  numberOfDailyResults: 'Number of daily results',
  noSensorsAvailable: 'No sensors found. Please select another asset.',
  noDetectionsFound: 'No detections found.',
  noClassesAvailable: 'No classes available.',
  hideDetections: 'Hide items outside detections area',
  showDetections: 'Show items outside detections area',
  results: 'Results',
  filteredResults: 'Filtered results',
  probabilityFilterDescription:
    'This slider may be used to filter results by the probability of a detection being true.',
  colorFilterDescription:
    'A detection is considered of a certain color if that color constitutes at least 10% of the image.',
  timeSelectorDescription:
    'This slider may be used to filter results by minutes and seconds within the selected hour in "Results". To change the selected hour, it should be changed in the "Results" selector.',
  resultsSelectorDescription:
    'This selector is used to choose the specific hour of the selected day within which you want to search for results. To additionally filter by a range of minutes and seconds, please use the selector at the top right corner of this tool.',
  resultsSelectorDescriptionMobile:
    'This selector is used to choose the specific hour of the selected day within which you want to search for results. To additionally filter by a range of minutes and seconds, please use the selector below.',
  pagination: function (from: number, to: number, total: number): string {
    return `${from} to ${to} of ${total} results`;
  },
  youCanNavigateBetweenDetection:
    'You can navigate between detections using the arrow keys',
  maxNumberOfImages:
    'You cannot select any more images. The maximum number is 100.',
};
