export const CountReport = {
  countReport: 'Reporte de conteo',
  countReports: 'Reportes de conteo',
  createReport: 'Crear reporte',
  editReport: 'Editar reporte',
  deleteReport: 'Eliminar reporte',
  section: 'Sección',
  sections: 'Secciones',
  createSection: 'Crear sección',
  editSection: 'Editar sección',
  deleteSection: 'Eliminar sección',
  element: 'Elemento',
  elements: 'Elementos',
  createElement: 'Crear elemento',
  editElement: 'Editar elemento',
  deleteElement: 'Eliminar elemento',
  sortElements: 'Ordenar elementos',
  addSeries: 'Agregar series',
  addSingleSeries: 'Agregar serie',
  listOfSeries: 'Lista de series',
  deleteSeries: 'Borrar serie',
  manageUsers: 'Administrar usuarios',
  isSelected: 'Seleccionado',
  showChanges: 'Mostrar cambios',
  noChanges: 'No ha habido cambios.',
  addedUsers: 'Usuarios agregados',
  deletedUsers: 'Usuarios eliminados',
  recentChanges: 'Cambios recientes',
  duplicateElement: 'Duplicar elemento',
  duplicateSection: 'Duplicar sección',
  duplicateReport: 'Duplicar reporte',
  Messages: {
    selectCountReport:
      'Por favor seleccione un reporte de conteo en la barra lateral izquierda.',
    sectionsEmpty: 'No hay secciones creadas aún.',
    elementsEmpty: 'No hay elementos creados aún.',
    doYouReallyWantToDeleteReport:
      '¿Estás seguro de que quieres eliminar el reporte de conteo ',
    onceYouHaveDeletedThisReport:
      'Una vez eliminado este reporte de conteo, sus información asociada será eliminada y no podrá ser recuperada.',
    doYouReallyWantToDeleteSection:
      '¿Estás seguro de que quieres eliminar la sección ',
    onceYouHaveDeletedThisSection:
      'Una vez eliminada esta sección, sus información asociada será eliminada y no podrá ser recuperada.',
    doYouReallyWantToDeleteElement:
      '¿Estás seguro de que quieres eliminar el elemento ',
    onceYouHaveDeletedThisElement:
      'Una vez eliminado este elemento, sus información asociada será eliminada y no podrá ser recuperada.',
    dragAndDropSectionsToSortThem: 'Arrastra las secciones para ordenarlas.',
    noAvailableReportsFound: 'No se han encontrado reportes disponibles.',
    noSectionsFound: 'Aún no hay secciones creadas.',
    noElementsFound: 'Aún no hay elementos creados.',
    twoSeriesCannotHaveTheSameName:
      'Dos series no pueden tener el mismo nombre.',
  },
  dateRange: 'Intervalo de fechas',
  dateRangeValues: {
    day: 'Diario',
    week: 'Semanal',
    month: 'Mensual',
  },
  dateRangeOptions: [
    {
      label: 'Diario',
      value: 'day',
    },
    {
      label: 'Semanal',
      value: 'week',
    },
    {
      label: 'Mensual',
      value: 'month',
    },
  ],
  Form: {
    columns: 'Columnas',
    groupInterval: 'Intervalo de agregación',
    addDifference: 'Añadir diferencia',
    addChart: 'Añadir gráfico',
    xLabel: 'Etiqueta eje X',
    yLabel: 'Etiqueta eje Y',
    xAxisType: 'Tipo eje X',
    groupMode: 'Modo agrupación',
    categoryType: 'Tipo de categoría',
    enterCountReportName: 'Ingrese el nombre del reporte de conteo',
    enterSectionName: 'Ingrese el nombre de la sección',
    enterElementName: 'Ingrese el nombre del elemento',
    enterSeriesName: 'Ingrese el nombre de la serie',
    enterXLabel: 'Ingrese etiqueta eje X',
    enterYLabel: 'Ingrese etiqueta eje Y',
    enterSuffix: 'Ingrese sufijo',
    enterPrefix: 'Ingrese prefijo',
  },
  elementTypeOptions: [
    { label: 'Indicador', value: 'indicator' },
    { label: 'Gráfico', value: 'chart' },
    { label: 'Tabla', value: 'table' },
    { label: 'Mapa de calor', value: 'heatmap' },
  ],
  groupIntervalOptions: [
    { label: 'Por hora', value: 'hour' },
    { label: 'Diario', value: 'day' },
    { label: 'Semanal', value: 'week' },
    { label: 'Mensual', value: 'month' },
  ],
  groupModeOptions: [
    { label: 'Suma', value: 'sum' },
    { label: 'Promedio', value: 'average' },
    { label: 'Mínimo', value: 'min' },
    { label: 'Máximo', value: 'max' },
  ],
  categoryTypeValues: [
    {
      label: 'Categoría',
      value: 'category',
    },
    {
      label: 'Fecha y hora',
      value: 'datetime',
    },
  ],
  categoryTypeOptions: [
    { label: 'Por hora', value: 'hourOfDay' },
    {
      label: 'Por hora, solo días hábiles',
      value: 'hourOfWeekDay',
    },
    {
      label: 'Por hora, solo fines de semana',
      value: 'hourOfWeekendDay',
    },
    {
      label: 'Por día, etiquetas muestran día de la semana',
      value: 'dayOfWeek',
    },
    {
      label: 'Por día, solo días hábiles',
      value: 'weekday',
    },
    {
      label: 'Por día, solo fines de semana',
      value: 'weekend',
    },
    {
      label: 'Por día, etiquetas muestran día del mes',
      value: 'dayOfMonth',
    },
    {
      label: 'Por semana',
      value: 'weekOfMonth',
    },
  ],
  categoryTypeLabels: {
    hourOfDay: 'Hora',
    hourOfWeekDay: 'Hora (Solo días hábiles)',
    hourOfWeekendDay: 'Hora (Solo fines de semana)',
    dayOfWeek: 'Día de la semana',
    weekday: 'Día (Solo días hábiles)',
    weekend: 'Día (Solo fines de semana)',
    dayOfMonth: 'Día del mes',
    weekOfMonth: 'Semana',
  },
  SeriesForm: {
    chartType: 'Tipo de gráfico',
    suffix: 'Sufijo',
    prefix: 'Prefijo',
    virtualExpression: 'Expresión virtual',
    precision: 'Precisión',
    chartTypeOptions: [
      { label: 'Gráfico de barras', value: 'bar' },
      { label: 'Gráfico de líneas', value: 'line' },
      { label: 'Gráfico de líneas con área', value: 'area' },
      { label: 'Diagrama de dispersión', value: 'scatter' },
      { label: 'Gráfico de splines', value: 'spline' },
      { label: 'Gráfico de splines con área', value: 'splineArea' },
    ],
  },
};
