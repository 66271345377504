export const GranularityDashboard = {
  granularity: 'Granulometría',
  volume: 'Volumen',
  percentage: 'Porcentaje',
  size: 'Tamaño',
  portico: 'Pórtico',
  empty: 'Vacío',
  material: 'Material',
  desmonte: 'Desmonte',
};
