type SpinnerProps = {
  size?: 'small' | 'medium' | 'large';
  absolute?: boolean;
};

export function Spinner(props: SpinnerProps = {}) {
  return !props.absolute ? (
    <div className="app-spinner">
      <div className="app-spinner__bg"></div>
      <div className="app-spinner__logo"></div>
    </div>
  ) : (
    <div className="app-spinner-backdrop">
      <div className="app-spinner">
        <div className="app-spinner__bg"></div>
        <div className="app-spinner__logo"></div>
      </div>
    </div>
  );
}
