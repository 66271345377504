export const ForensicReconstruction = {
  forensicReconstruction: 'Forensic reconstruction',
  cameraOverview: 'Camera overview',
  loadTemplate: 'Load template',
  saveTemplate: 'Save template',
  templateTitle: 'Template title',
  templateDescription: 'Template description',
  selectCameras: 'Select cameras',
  selectCamera: 'Select camera',
  addANewCamera: 'Add a new camera',
  addCameras: 'Add cameras',
  editCamera: 'Edit camera',
  cameraName: 'Camera name',
  addElementsToStoryline: 'Add elements to storyline',
  createNewStoryline: 'Create new storyline',
  createStoryline: 'Create storyline',
  editStoryline: 'Edit storyline',
  deleteStoryline: 'Delete storyline',
  exportStoryline: 'Export storyline',
  addToStoryline: 'Add to storyline',
  goToStorylines: 'Go to Storylines',
  addANewCameraToBegin: 'Add a new camera to begin',
  viewSelectedImages: 'View selected images',
  selectSpeedMultiplier: 'Select speed multiplier',
  downloadSelectedImages: 'Download selected images',
  hideDetections: 'Hide detections',
  showDetections: 'Show detections',
  Tutorial: {
    step1Title: 'Welcome to our new Forensic Reconstruction tool!',
    step1Description: `With this tool, you'll be able to explore precise moments captured by cameras to reconstruct past events and create a detailed timeline.`,
    step2Title: 'Add a new camera',
    step2Description:
      'Here you can view selected cameras and also have the option to add new cameras to your list.',
    step3Title: 'Time selector',
    step3Description:
      'In this section, you can select the specific time at which you want to make your query. Use the single arrows to navigate between hours and the double arrows to move between days...',
    step4Title: 'Time selector',
    step4Description:
      '...You can also use scrolling to move through time! This way, you can effortlessly adjust the desired hour to obtain the precise results you are looking for.',
    step5Title: 'Other actions',
    step5Description:
      'You can view and download the images you have previously selected. Additionally, you can save and load templates that contain the selected cameras, allowing you to have predefined configurations for future queries. This functionality enables you to easily access and manage your selected images, as well as quickly set up predefined configurations for efficient future use.',
    step6Title: 'We hope you enjoy using this new tool!',
    step6Description:
      "We hope you thoroughly enjoy using it and feel free to explore at your own pace. If you have any questions or need assistance, don't hesitate to reach out to us. Wishing you an excellent experience with this new tool!",
  },
  Messages: {
    addSnapshotTitle: 'Add snapshot to Images?',
    addSnapshotDescription:
      'The video capture will be added to the Images section.',
    enterTemplateTitle: 'Enter template title',
    enterTemplateDescription: 'Enter template description',
    templateTitleRequired: 'Please provide a template title!',
    deleteCameraConfirmationTitle: 'Do you really want to delete camera',
    deleteCameraConfirmationDescription: 'The Images selection will be lost.',
    noImages: 'No images on this camera yet.',
    noVideos: 'No videos on this camera yet.',
    cameraNameRequired: 'Please provide a camera name!',
    selectCameraMessage:
      'To view images and videos, please select a camera from the list.',
    noImagesSelected: 'No images selected.',
    yourStorylineHasBeenUpdated:
      'Your storyline has been successfully updated.',
  },
  UploadTemplateModal: {
    uploadTemplate: 'Upload template',
    Messages: {
      clickOrDragFile: 'Click or drag file to this area to upload',
      youDoNotHavePermission:
        'You do not have permission to see the contents of this template.',
      pleaseUploadADifferentJSON:
        'Please upload a different JSON (.json) file or contact your system administrator.',
      pleaseUpload: 'Please upload',
      aSingleJSONFile: 'a single JSON (.json) file',
    },
  },
  WheelPicker: {
    resetDate: 'Reset date',
    previousDay: 'Previous day',
    previousHour: 'Previous hour',
    nextDay: 'Next day',
    nextHour: 'Next hour',
  },
  SelectedImagesModal: {
    doYouWantToDeselect: 'Do you want to deselect this image?',
    theCurrentImageWillBeRemoved:
      "The current image will be removed from the selected images. To add it back, you'll need to do it manually.",
  },
};
